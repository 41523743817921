<template>
  <div class="marginTop">
    <div class="banner">
      <img src="../../assets/imgss/banner.jpg" />
      <div class="show">
        <a class="show_img" href="javaScript:void(0);"></a>
      </div>
    </div>
    <div class="div_box content">
      <div class="Classic_now">
        <div class="Classic_title">
          <div class="Classic_title_c">4D CLASSIC</div>
        </div>
        <!-- 开奖组件 -->
        <LotteryNow />
      </div>
      <div class="Classic_record">
        <div class="Classic_title">
          <div class="Classic_title_c">
            {{ $t("Awards.DrawingHistory") }}
          </div>
          <div class="more" @click="GoTop">
            <router-link to="/lotteryAnnouncement">
              {{ $t("more") }}>></router-link
            >
          </div>
        </div>
        <template v-for="list in dataList">
          <LotteryHistoryModel :list="list" />
        </template>
      </div>
      <div class="comment">{{ $t("Notes") }}:{{ $t("NotesContent") }}.</div>
    </div>
  </div>
</template>
<script>
import LotteryHistoryModel from "../../components/common/LotteryHistoryModel";
import LotteryNow from "../../components/common/LotteryNow";
export default {
  name: "WebHome",
  data() {
    return {
      dataList: [],
    };
  },
  components: {
    LotteryHistoryModel,
    LotteryNow,
  },
  mounted() {
    const axios = require("axios");
    var _this = this;
    axios
      .get(
        "https://www.kkonline.app/game/TL4DHomeWeb/GetOpenVideo?pageindex=1&pagesize=3&type=1"
      )
      .then(function (res) {
        for (var i = 1; i < res.data.data.length; i++) {
          res.data.data[i]["ifShow"] = true;
          _this.dataList.push(res.data.data[i]);
        }
      });
  },
  methods: {
    GoTop() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
  },
};
</script>
<style scoped>
/*banner*/
.banner {
  min-width: 920px;
  display: flex;
  justify-content: center;
  width: 100%;
  /* margin-top:90px; */
}

.banner img {
  min-width: 1480px;
  margin: 0px;
  width: 100%;
  height: auto;
  display: block;
}

.show {
  position: absolute;
  background: url(../../assets/imgss/show_img.png) no-repeat;
  height: 48px;
  width: 138px;
  left: 400px;
  top: 420px;
}

.show a {
  display: block;
  height: 48px;
  width: 138px;
}

/*content*/
.content {
  margin-top: -50px;
  position: relative;
}

.Classic_now {
  background-color: white;
  padding-bottom: 20px;
}

.Classic_title {
  background: url(../../assets/imgss/title.png) no-repeat;
  height: 50px;
  width: 1200px;
  background-color: #f8eee2;
}

.Classic_title_c {
  width: 205px;
  text-align: center;
  color: #806142;
  font-size: 20px;
  line-height: 50px;
  font-weight: bolder;
  background-color: transparent;
}

.Classic_record {
  margin-top: 12px;
  background-color: white;
  padding-bottom: 40px;
}

.Classic_record .Classic_title {
  height: 50px;
  width: 1200px;
  background-color: #f8eee2;
  background-image: linear-gradient(to right, #f8eee2, #f8eee2, white);
  display: flex;
  justify-content: space-between;
}

.Classic_record .Classic_title_c {
  background: url(../../assets/imgss/title.png) no-repeat;
  width: 209px;
  text-align: center;
  color: #806142;
  font-size: 20px;
  line-height: 50px;
  font-weight: bolder;
  background-color: transparent;
}

.Classic_record .more {
  line-height: 50px;
  padding-right: 40px;
}

.Classic_record .more a {
  color: #898888;
}

.comment {
  margin-top: 8px;
  color: #717171;
}
</style>