<template>
  <div class="Classic_box">
    <div class="Classic_content">
      <div class="Classic_content_title">
        <span>4D CLASSIC</span>{{ $t("Awards.NO")
        }}<label>{{ dataList.issue }}</label
        >{{ $t("Awards.qi") }}
      </div>
      <div class="c_content">
        <table class="topThree" cellspacing="0">
          <thead>
            <td>{{ $t("Awards.firstPrize") }}</td>
            <td>{{ $t("Awards.SecondPlace") }}</td>
            <td>{{ $t("Awards.ThirdPrize") }}</td>
          </thead>
          <tr>
            <td>
              {{
                dataListReslut[0] == ""
                  ? "--"
                  : dataListReslut[0] == undefined
                  ? "--"
                  : dataListReslut[0]
              }}
            </td>
            <td>
              {{
                dataListReslut[1] == ""
                  ? "--"
                  : dataListReslut[1] == undefined
                  ? "--"
                  : dataListReslut[1]
              }}
            </td>
            <td>
              {{
                dataListReslut[2] == ""
                  ? "--"
                  : dataListReslut[2] == undefined
                  ? "--"
                  : dataListReslut[2]
              }}
            </td>
          </tr>
        </table>
        <table class="table_other" cellspacing="0">
          <thead>
            <td colspan="4" style="border-top: none">
              {{ $t("Awards.SpecialPrize") }}
            </td>
          </thead>
          <tr>
            <td>
              {{
                dataListReslut[3] == ""
                  ? "--"
                  : dataListReslut[3] == undefined
                  ? "--"
                  : dataListReslut[3]
              }}
            </td>
            <td>
              {{
                dataListReslut[4] == ""
                  ? "--"
                  : dataListReslut[4] == undefined
                  ? "--"
                  : dataListReslut[4]
              }}
            </td>
            <td>
              {{
                dataListReslut[5] == ""
                  ? "--"
                  : dataListReslut[5] == undefined
                  ? "--"
                  : dataListReslut[5]
              }}
            </td>
            <td>
              {{
                dataListReslut[6] == ""
                  ? "--"
                  : dataListReslut[6] == undefined
                  ? "--"
                  : dataListReslut[6]
              }}
            </td>
          </tr>
          <tr>
            <td>
              {{
                dataListReslut[7] == ""
                  ? "--"
                  : dataListReslut[7] == undefined
                  ? "--"
                  : dataListReslut[7]
              }}
            </td>
            <td>
              {{
                dataListReslut[8] == ""
                  ? "--"
                  : dataListReslut[8] == undefined
                  ? "--"
                  : dataListReslut[8]
              }}
            </td>
            <td>
              {{
                dataListReslut[9] == ""
                  ? "--"
                  : dataListReslut[9] == undefined
                  ? "--"
                  : dataListReslut[9]
              }}
            </td>
            <td>
              {{
                dataListReslut[10] == ""
                  ? "--"
                  : dataListReslut[10] == undefined
                  ? "--"
                  : dataListReslut[10]
              }}
            </td>
          </tr>
          <tr>
            <td class="kong"></td>
            <td>
              {{
                dataListReslut[11] == ""
                  ? "--"
                  : dataListReslut[11] == undefined
                  ? "--"
                  : dataListReslut[11]
              }}
            </td>
            <td>
              {{
                dataListReslut[12] == ""
                  ? "--"
                  : dataListReslut[12] == undefined
                  ? "--"
                  : dataListReslut[12]
              }}
            </td>
            <td class="kong"></td>
          </tr>
        </table>
        <table class="table_other" cellspacing="0">
          <thead>
            <td colspan="4" style="border-top: none">
              {{ $t("Awards.ConsolationPrize") }}
            </td>
          </thead>
          <tr>
            <td>
              {{
                dataListReslut[13] == ""
                  ? "--"
                  : dataListReslut[13] == undefined
                  ? "--"
                  : dataListReslut[13]
              }}
            </td>
            <td>
              {{
                dataListReslut[14] == ""
                  ? "--"
                  : dataListReslut[14] == undefined
                  ? "--"
                  : dataListReslut[14]
              }}
            </td>
            <td>
              {{
                dataListReslut[15] == ""
                  ? "--"
                  : dataListReslut[15] == undefined
                  ? "--"
                  : dataListReslut[15]
              }}
            </td>
            <td>
              {{
                dataListReslut[16] == ""
                  ? "--"
                  : dataListReslut[16] == undefined
                  ? "--"
                  : dataListReslut[16]
              }}
            </td>
          </tr>
          <tr>
            <td>
              {{
                dataListReslut[17] == ""
                  ? "--"
                  : dataListReslut[17] == undefined
                  ? "--"
                  : dataListReslut[17]
              }}
            </td>
            <td>
              {{
                dataListReslut[18] == ""
                  ? "--"
                  : dataListReslut[18] == undefined
                  ? "--"
                  : dataListReslut[18]
              }}
            </td>
            <td>
              {{
                dataListReslut[19] == ""
                  ? "--"
                  : dataListReslut[19] == undefined
                  ? "--"
                  : dataListReslut[19]
              }}
            </td>
            <td>
              {{
                dataListReslut[20] == ""
                  ? "--"
                  : dataListReslut[20] == undefined
                  ? "--"
                  : dataListReslut[20]
              }}
            </td>
          </tr>
          <tr>
            <td class="kong"></td>
            <td>
              {{
                dataListReslut[21] == ""
                  ? "--"
                  : dataListReslut[21] == undefined
                  ? "--"
                  : dataListReslut[21]
              }}
            </td>
            <td>
              {{
                dataListReslut[22] == ""
                  ? "--"
                  : dataListReslut[22] == undefined
                  ? "--"
                  : dataListReslut[22]
              }}
            </td>
            <td class="kong"></td>
          </tr>
        </table>
      </div>
    </div>
    <div class="cutTime">
      <div class="cutTime_backImg">
        <span class="hour">{{ hour }}</span>
        <span class="minute">{{ minute }}</span>
        <span class="second">{{ second }}</span>
      </div>
      <div v-bind:class="cutTimeTime">
        {{ $t("Awards.NO") }}{{ dataList.issue }}{{ $t("Awards.qi") }}
        {{ $t("Awards.cutOffTime") }}：{{ endTime }} （UTC+7）
      </div>
      <div v-bind:class="cutTimeTimeOver">等待下次开盘</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LotteryNow",
  data() {
    return {
      //    count: '', //倒计时
      seconds: 0,
      hour: "00",
      minute: "00",
      second: "00",
      endTime: null,
      dataList: [],
      dataListReslut: [],
      cutTimeTime: "cutTime_time",
      cutTimeTimeOver: "cutTime_time_hide",
    };
  },
  mounted() {
    const axios = require("axios");
    var _this = this;
    axios
      .get(
        "https://www.kkonline.app/game/TL4DHomeWeb/GetOpenVideo?pageindex=1&pagesize=1&type=1"
      )
      .then(function (res) {
        _this.dataList = res.data.data[0];
        var data_d = _this.dataList.lotterynum.split(",");
        for (let index = 0; index < data_d.length; index++) {
          _this.dataListReslut.push(data_d[index]);
        }
        _this.endTime = res.data.data[0].liveEndTime;
        let timeNow = Date.parse(new Date()) / 1000;
        let timeGet = Date.parse(_this.endTime) / 1000;
        if (timeGet > timeNow) {
          _this.seconds = timeGet - timeNow;
        } else {
          _this.seconds = 0;
        }
      });
    this.Time(); //调用定时器
  },
  methods: {
    countDown() {
      if (this.seconds > 0) {
        let h = parseInt((this.seconds / (60 * 60)) % 24);
        h = h < 10 ? "0" + h : h;
        let m = parseInt((this.seconds / 60) % 60);
        m = m < 10 ? "0" + m : m;
        let s = parseInt(this.seconds % 60);
        s = s < 10 ? "0" + s : s;
        this.hour = h;
        this.minute = m;
        this.second = s;
        this.cutTimeTime = 'cutTime_time';
        this.cutTimeTimeOver = 'cutTime_time_hide';
      } else {
        this.hour = "00";
        this.minute = "00";
        this.second = "00";
        this.cutTimeTime = 'cutTime_time_hide';
        this.cutTimeTimeOver = 'cutTime_time';
      }
    },
    //定时器没过1秒参数减1
    Time() {
      setInterval(() => {
        this.seconds -= 1;
        this.countDown();
      }, 1000);
    },
  },
};
</script>

<style scoped>
.kong {
  background-color: #e9e9e9;
}

.Classic_content {
  padding: 10px 30px;
}

.Classic_content_title {
  width: 650px;
  font-size: 25px;
  height: 40px;
  line-height: 40px;
}

.Classic_content_title span {
  font-size: 30px;
  margin-top: 10px;
  font-weight: bolder;
  padding-right: 15px;
  padding-left: 10px;
}

.Classic_content_title label {
  font-size: 25px;
  color: red;
  margin-top: 10px;
  font-weight: 400;
  padding: 0px 5px;
}

.Classic_box {
  display: flex;
  flex-direction: row;
}

.topThree {
  width: 650px;
  border-collapse: collapse;
  margin-top: 10px;
}

.topThree thead {
  background-color: #eac894;
  color: #5b3411;
}

.topThree td {
  border: 1px solid #eac894;
  width: 220px;
  text-align: center;
  font-size: 20px;
  font-weight: bolder;
}

.table_other {
  width: 650px;
  border-collapse: collapse;
}

.table_other thead {
  background-color: #f6eddc;
  color: #5b3411;
}

.table_other td {
  border: 1px solid #eac894;
  text-align: center;
  font-size: 20px;
  font-weight: bolder;
}

.cutTime {
  padding-top: 100px;
  height: 92px;
  line-height: 92px;
  font-size: 66px;
}

.cutTime_backImg {
  display: inline-block;
  background: url(../../assets/imgss/cutTime_back.jpg) no-repeat;
  width: 450px;
  height: 108px;
  line-height: 108px;
}

.cutTime span {
  display: inline-block;
  color: red;
  font-weight: bolder;
  font-size: 66px;
  letter-spacing: 24px;
  width: 123px;
}

.cutTime .hour {
  padding-left: 16px;
}

.cutTime .minute {
  padding-left: 27px;
}

.cutTime .second {
  padding-left: 28px;
}

.cutTime .cutTime_time {
  text-align: center;
  font-size: 18px;
  color: #717171;
  width: 440px;
  line-height: 28px;
  font-weight: bolder;
  margin-top: 10px;
}
.cutTime .cutTime_time_hide {
  display: none;
  text-align: center;
  font-size: 18px;
  color: #717171;
  width: 440px;
  line-height: 28px;
  font-weight: bolder;
  margin-top: 10px;
}
</style>