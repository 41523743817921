<template>
  <div class="marginTop">
    <div class="banner">
      <img src="../../assets/imgss/vido_banner.jpg" />
    </div>
    <div class="navigation div_box">
      <router-link to="/home"> {{ $t("navigation.home") }}</router-link
      >-
      <span>{{ $t("navigation.video") }}</span>
    </div>
    <div class="content div_box">
      <div class="live">
        <div class="live_title">
          <div class="live_title_c">
            {{ $t("LiveStreamingLottery") }}
          </div>
        </div>
        <div class="live_content">
          <div class="liveBox">
            <canvas
              class="canvas"
              id="liveVideo"
              width="960"
              height="600"
              @mousemove="liveMove"
              @mouseout="liveOut"
            />
            <div
              v-bind:class="liveBanner"
              @mousemove="liveMove"
              @mouseout="liveOut"
            >
              <div class="play_box">
                <div></div>
                <div Class="live_text">LIVE</div>
              </div>
              <div class="full" @click="full"></div>
            </div>
          </div>
          <div v-bind:class="msg">
            4D CLASSIC直播时间为
            <span style="color: red">{{ starTime }}-{{ endTime }}</span>
            (UTC+7),敬请观看.
          </div>
          <img v-bind:class="liveBg" src="../../assets/imgss/live.jpg" />
        </div>
      </div>
      <div class="video">
        <div class="Classic_title">
          <div class="Classic_title_c">
            {{ $t("Awards.DrawingHistory") }}
          </div>
          <div class="Classic_title_s">
            <div class="history">
              <div v-bind:class="thisYear" @click="check(1)">
                {{ $t("ThisYear") }}
              </div>
              <div v-bind:class="lastYear" @click="check(0)">
                {{ $t("LastYear") }}
              </div>
            </div>
            <div class="search" @click="onblur">
              <input
                type="text"
                v-model="searchValue"
                ref="search"
                placeholder="请输入期号"
                @keydown="search"
                class="search_i"
              />
            </div>
          </div>
        </div>
        <div class="video_content">
          <div v-for="(list, index) in dataList" :key="index">
            <VideoForm class="each" :list="list" />
          </div>
        </div>
        <div class="page">
          <span @click="Prev">上一页</span
          ><span style="margin-left: 10px">{{ pageIndex }}/{{ pageCount }}</span
          ><span @click="PgDn" style="margin-left: 10px">下一页</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="../../assets/js/layer"></script>
<script>
import flvjs from "flv.js";
import VideoForm from "../../components/common/VideoForm.vue";
export default {
  name: "LotteryVideo",
  components: { VideoForm },
  data() {
    return {
      thisYear: "thisYear",
      lastYear: "lastYear",
      dataList: [],
      liveBg: "bg_hide",
      isplay: true,
      pageIndex: 1,
      pagesize: 9,
      type: 1,
      searchValue: "",
      pageCount: 1,
      liveStartTime: "",
      liveEndTime: "",
      msg: "msg",
      liveBanner: "live_banner_h",
      playCss: "play_stop",
      liveUrl: "",
      timer: null,
    };
  },
  created: function () {
    this.timer = setInterval(() => {
      this.checkAuditTime(this.liveStartTime, this.liveEndTime);
    }, 2000);
  },
  methods: {
    liveMove() {
      this.liveBanner = "live_banner";
    },
    liveOut() {
      this.liveBanner = "live_banner_h";
    },
    play() {
      if (this.isplay) {
        this.np.start(this.liveUrl);
        this.isplay = false;
        this.playCss = "play_star";
      } else {
        this.np.stop();
        this.isplay = true;
        this.playCss = "play_stop";
      }
    },
    full() {
      this.np.fullscreen();
    },
    checkAuditTime(startTime, endTime) {
      let startDate = new Date(startTime).getTime();
      let endDate = new Date(endTime).getTime();
      let nowDate = new Date().getTime();
      if (nowDate > startDate && nowDate < endDate) {
        if (this.isplay) {
          this.liveBg = "bg_hide";
          this.msg = "msg_hide";
          this.np.start(this.liveUrl);
          // clearInterval(this.timer);
          // this.isplay = true;
          // this.playCss = "play_star";
        }
      } else {
        this.liveBg = "bg";
        this.msg = "msg";
        this.np.stop();
        // this.isplay = false;
      }
    },
    check(val) {
      const axios = require("axios");
      var _this = this;
      if (val == 1) {
        this.thisYear = "thisYear";
        this.lastYear = "lastYear";
        axios
          .get(
            "https://www.kkonline.app/game/TL4DHomeWeb/GetOpenVideo?pageindex=" +
              this.pageIndex +
              "&pagesize=" +
              this.pagesize +
              "&type=" +
              val
          )
          .then(function (res) {
            if (new Date() > new Date(res.data.data[0].liveEndTime)) {
              _this.dataList = res.data.data;
            } else {
              for (var i = 1; i < res.data.data.length; i++) {
                if (i != 0) {
                  _this.dataList.push(res.data.data[i]);
                }
              }
            }
            _this.pageIndex = 1;
            _this.pageCount = Math.ceil(res.data.totalcount / _this.pagesize);
          });
      } else {
        this.thisYear = "lastYear";
        this.lastYear = "thisYear";
        axios
          .get(
            "https://www.kkonline.app/game/TL4DHomeWeb/GetOpenVideo?pageindex=" +
              this.pageIndex +
              "&pagesize=" +
              this.pagesize +
              "&type=" +
              val
          )
          .then(function (res) {
            _this.dataList = res.data.data;
            _this.pageIndex = 1;
            if (res.data.totalcount == undefined) _this.pageCount = 1;
            else
              _this.pageCount = Math.ceil(res.data.totalcount / _this.pagesize);
          });
      }
    },
    onblur() {
      this.$refs.search.focus();
    },
    Prev() {
      //上一页
      if (this.pageIndex > 1) {
        this.pageIndex -= 1;
      } else {
        this.pageIndex = 1;
      }
      const axios = require("axios");
      var _this = this;
      axios
        .get(
          "https://www.kkonline.app/game/TL4DHomeWeb/GetOpenVideo?pageindex=" +
            this.pageIndex +
            "&pagesize=" +
            this.pagesize +
            "&type=" +
            this.type
        )
        .then(function (res) {
          if (this.pageIndex == 1) {
            if (new Date() > new Date(res.data.data[0].liveEndTime)) {
              _this.dataList = res.data.data;
            } else {
              for (var i = 1; i < res.data.data.length; i++) {
                if (i != 0) {
                  _this.dataList.push(res.data.data[i]);
                }
              }
            }
          } else {
            _this.dataList = res.data.data;
          }
        });
    },
    PgDn() {
      //下一页
      if (this.pageIndex == this.pageCount) {
        this.pageIndex = this.pageCount;
      } else {
        this.pageIndex += 1;
      }
      const axios = require("axios");
      var _this = this;
      axios
        .get(
          "https://www.kkonline.app/game/TL4DHomeWeb/GetOpenVideo?pageindex=" +
            this.pageIndex +
            "&pagesize=" +
            this.pagesize +
            "&type=" +
            this.type
        )
        .then(function (res) {
          _this.dataList = res.data.data;
        });
    },
    search() {
      const axios = require("axios");
      var _this = this;
      axios
        .get(
          "https://www.kkonline.app/game/TL4DHomeWeb/GetOpenVideo?pageindex=" +
            this.pageIndex +
            "&pagesize=" +
            this.pagesize +
            "&type=" +
            this.type +
            "&issue=" +
            this.searchValue
        )
        .then(function (res) {
          _this.dataList = res.data.data;
          _this.pageIndex = 1;
          _this.pageCount = 1;
        });
    },
  },
  mounted() {
    this.np = new NodePlayer();
    this.np.setView("liveVideo");
    this.np.on("stats", (s) => {
      console.log(s);
    });
    const axios = require("axios");
    var _this = this;
    axios
      .get(
        "https://www.kkonline.app/game/TL4DHomeWeb/GetOpenVideo?pageindex=" +
          this.pageIndex +
          "&pagesize=" +
          this.pagesize +
          "&type=" +
          this.type
      )
      .then(function (res) {
        if (new Date() > new Date(res.data.data[0].liveEndTime)) {
          _this.dataList = res.data.data;
        } else {
          for (var i = 1; i < res.data.data.length; i++) {
            if (i != 0) {
              _this.dataList.push(res.data.data[i]);
            }
          }
        }
        _this.pageCount = Math.ceil(res.data.totalcount / _this.pagesize);
        _this.liveStartTime = res.data.data[0].liveStartTime;
        _this.liveEndTime = res.data.data[0].liveEndTime;
        _this.liveUrl = res.data.data[0].liveUrl;
      });
    // .slice(11, 16)
  },
  computed: {
    starTime: {
      get() {
        const date = new Date(this.liveStartTime);
        return (
          date.getMonth() +
          1 +
          "月" +
          date.getDate() +
          "日" +
          date.getHours() +
          ":" +
          date.getMinutes()
        );
      },
    },
    endTime: {
      get() {
        const date = new Date(this.liveEndTime);
        return (
          date.getMonth() +
          1 +
          "月" +
          date.getDate() +
          "日" +
          date.getHours() +
          ":" +
          date.getMinutes()
        );
      },
    },
  },
};
</script>
<style scoped>
.full {
  margin-top: 15px;
  width: 20px;
  height: 20px;
  background: url(../../assets/imgss/full.png) no-repeat;
}
.full:hover {
  cursor: pointer;
}
.play_box {
  display: flex;
}
.live_text {
  font-size: 18px;
  color: white;
  font-weight: 400;
  margin-top: 13px;
  margin-left: 30px;
}
.play_star {
  margin-top: 15px;
  width: 20px;
  height: 20px;
  background: url(../../assets/imgss/play_a.png) no-repeat;
}
.play_stop {
  margin-top: 15px;
  width: 20px;
  height: 20px;
  background: url(../../assets/imgss/play_b.png) no-repeat;
}
.play_anniu:hover {
  cursor: pointer;
}
.canvas {
  /* margin: 0px 200px; */
}
.liveBox {
  position: relative;
  margin-left: 120px;
  width: 960px;
}
.live_banner {
  padding: 0px 30px;
  display: block;
  position: absolute;
  display: flex;
  justify-content: space-between;
  bottom: 5px;
  width: 900px;
  height: 50px;
  background-color: #2b2b2b;
  opacity: 0.4; /* IE9+ */
  filter: Alpha(opacity=40); /* IE6|7|8|9兼容  */
}
.live_banner_h {
  display: none;
  position: absolute;
  bottom: 5px;
  width: 1168px;
  height: 50px;
  background-color: #aaaaaa;
  opacity: 0.8; /* IE9+ */
  filter: Alpha(opacity=80); /* IE6|7|8|9兼容  */
}
.msg {
  position: absolute;
  color: white;
  font-size: 18px;
  z-index: 40;
  top: 300px;
  left: 280px;
}

.msg_hide {
  display: none;
  position: absolute;
  color: white;
  font-size: 18px;
  z-index: 40;
  top: 300px;
  left: 280px;
}

.msg span {
  font-size: 18px;
}

.page {
  text-align: center;
  color: #d5b581;
  padding-bottom: 20px;
}

.page span:hover {
  cursor: pointer;
}

.video {
  background-color: white;
}

.video_content {
  width: 1160px;
  margin: 0 auto;
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-bottom: 20px;
}

.video_content .each {
  margin-left: 20px;
}

.Classic_title {
  width: 1200px;
  background-color: #f8eee2;
  /* background-image: linear-gradient(to right, #F8EEE2, #F8EEE2, white); */
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.Classic_title_c {
  background: url(../../assets/imgss/title.png) no-repeat;
  height: 50px;
  width: 209px;
  text-align: center;
  color: #806142;
  font-size: 20px;
  line-height: 50px;
  font-weight: bolder;
}

.Classic_title_s {
  width: 500px;
  display: flex;
  justify-content: space-between;
}

.live_title {
  height: 50px;
  width: 1200px;
  background-color: #f8eee2;
  display: flex;
  justify-content: space-between;
}

.live_title_c {
  background: url(../../assets/imgss/title.png) no-repeat;
  width: 209px;
  text-align: center;
  color: #806142;
  font-size: 20px;
  line-height: 50px;
  font-weight: bolder;
  background-color: transparent;
}

.search {
  background: url(../../assets/imgss/search.png) no-repeat;
  height: 40px;
  width: 175px;
  margin-top: 5px;
  margin: 5px 50px 0px 0px;
  line-height: 40px;
}

.search input {
  /* font-weight: bolder; */
  border: 0;
  margin-left: 50px;
  outline: none;
  width: 100px;
  background-color: transparent;
  color: #929292;
  font-size: 18px;
  height: 40px;
}

.history {
  width: 220px;
  display: flex;
  justify-content: space-between;
}

.thisYear {
  width: 95px;
  height: 40px;
  background-color: #ebc996;
  color: #5b3411;
  font-weight: bolder;
  font-size: 20px;
  margin-top: 5px;
  text-align: center;
  line-height: 40px;
  border-radius: 50px;
}

.lastYear {
  width: 95px;
  height: 40px;
  color: #808080;
  font-weight: bolder;
  font-size: 20px;
  margin-top: 5px;
  text-align: center;
  line-height: 40px;
}

.lastYear:hover {
  cursor: pointer;
}

.thisYear:hover {
  cursor: pointer;
}

.live {
  background-color: white;
}

.live_content {
  margin: 15px auto;
  text-align: center;
  padding-bottom: 10px;
  width: 1168px;
  height: 600px;
  position: relative;
}

.live_content .bg {
  width: 1168px;
  height: 600px;
  position: absolute;
  top: 0px;
  left: 0px;
}

.live_content .bg_hide {
  width: 1168px;
  height: 450px;
  position: absolute;
  top: 0px;
  left: 0px;
  display: none;
}

.content {
  margin-top: 10px;
}

.navigation {
  margin-top: 20px;
}

.navigation span {
  font-weight: bold;
  color: #3f3f3f;
}

/*banner*/
.banner {
  display: flex;
  justify-content: center;
  min-width: 920px;
}

.banner img {
  min-width: 1480px;
  width: 100%;
  height: auto;
}
</style>