import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home'
import WebHome from '../views/pages/WebHome'
import AboutUs from '../views/pages/AboutUs'
import Faq from '../views/pages/Faq'
import LotteryAnnouncement from '../views/pages/LotteryAnnouncement'
import LotteryVideo from '../views/pages/LotteryVideo'

Vue.use(VueRouter)
export default new VueRouter({
    routes: [
        {
            path: '',
            redirect:'/home'
        },
        {
            path: '/home',
            component: WebHome
        },
        {
            path: '/aboutUs',
            component: AboutUs
        },
        {
            path: '/faq',
            component: Faq
        },
        {
            path: '/lotteryAnnouncement',
            component: LotteryAnnouncement
        },
        {
            path: '/lotteryVideo',
            component: LotteryVideo
        },
    ]
})