<template>
    <div class="video_box">
        <div class="video_show">
            <img class="bofang" v-bind:class="bofang" @click="handleClick(list)" src="../../assets/imgss/bofang.png" />
            <video ref="video1" width="360">
                <source :src=url type="video/mp4">
                {{ $t('NotesVideo') }}
            </video>
            <div class="video_phase">
                <div class="video_phase_c">
                    {{ $t('Awards.NO') }}<label>{{ list.issue }}</label>{{ $t('Awards.qi') }} {{ $t('navigation.video') }}
                </div>
            </div>
        </div>
        <div class="money">
            【4D CLASSIC】 {{ $t('VideoPric') }}<span>{{ maxAward }}</span>{{ $t('yuan') }}
        </div>
        <VideoPlay v-if="Visiable" ref="dialog"></VideoPlay>
    </div>
</template>

<script>
import VideoPlay from "./VideoPlay";
export default {
    name: 'Video',
    props: ['list'],
    components:{
        VideoPlay
    },
    data() {
        return {
            bofang: 'bofang',
            maxAward: '',
            url: '',
            AwardsNum: '',
            Visiable:false
        }

    },
    methods: {
        handleClick(data){
          this.Visiable=true;
          this.$nextTick(()=>{
            this.$refs.dialog.init(data);
          })
        },
        playPause() {
            var myVideo = this.$refs.video1
            if (myVideo.paused) {
                myVideo.play();
                this.bofang = "bofang_none";
            }
            else {
                myVideo.pause();
                this.bofang = "bofang";
            }
        },
        play() {
            var myVideo = this.$refs.video1
            if (this.bofang == "bofang") {
                myVideo.play();
                this.bofang = "bofang_none";
            }
            else {
                myVideo.pause();
                this.bofang = "bofang";
            }
        }
    },
    mounted() {
        this.$refs.video1.addEventListener("ended", function () {
            this.bofang = "bofang_none";
        })
        this.maxAward = this.list.maxAward;
        this.url = this.list.videourl;
    },
}
</script>

<style scoped>
.bofang {
    display: block;
    position: absolute;
    top: 50px;
    left: 140px;
    z-index: 200;
}
.bofang:hover {
    cursor: pointer;
}
.bofang_none {
    display: none;
    position: absolute;
    top: 50px;
    left: 140px;
    z-index: 200;
}

.video_box {
    width: 360px;
    margin-top: 20px;
    position: relative;
}

.video_show {
    position: relative;
    border: 1px solid black;
}

.video_phase {
    width: 350px;
    height: 38px;
    line-height: 38px;
    background-color: black;
    opacity: 0.5;
    position: absolute;
    bottom: 0px;
    color: white;
    padding-left: 10px;
}

.video_phase span {
    color: red;
    padding: 0px 5px;
}

.money {
    /* width: 360px; */
    height: 45px;
    line-height: 45px;
    padding-left: 6px;
    background-color: #F0F0F0;
    font-weight: bold;
    color: #808080;
}

.money span {
    color: red;
}
</style>