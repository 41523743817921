import Vue from 'vue';
import VueI18n from 'vue-i18n';
Vue.use(VueI18n);

export default new VueI18n({
    locale: 'zh',
    messages: {
        zh: {
            navigation: {
                home: '首页',
                notice: '开奖公告',
                video: '开奖视频',
                aboutUs: '关于我们',
                language: '简体中文'
            },
            Awards: {
                DrawingHistory: '开奖历史记录',
                firstPrize:'首奖',
                SecondPlace:'二奖',
                ThirdPrize:'三奖',
                SpecialPrize:'特别奖',
                ConsolationPrize:'安慰奖',
                NO:'第',
                qi:'期',
                cutOffTime:'截止时间',
            },
            Notes:'注',
            NotesContent:'有什么不明白可以观看视频回放',
            more:'更多',
            Copyright:'腾龙娱乐版权所有',
            ThisYear:'今年',
            LastYear:'去年',
            enterNum:'请输入期号',
            LiveStreamingLottery:'直播开奖',
            NotesVideo:'您的浏览器不支持 HTML5 video 标签。',
            VideoPric:'开出首奖',
            yuan:'元',
        },
        en: {
            navigation: {
                home: 'Home',
                notice: 'Notice',
                video: 'Video',
                aboutUs: 'About',
                language: 'English'
            },
            Awards: {
                DrawingHistory: 'Video History',
                firstPrize:'First Prize',
                SecondPlace:'Second Place',
                ThirdPrize:'Third Prize',
                SpecialPrize:'Special Prize',
                ConsolationPrize:'Consolation Prize',
                NO:'NO.',
                qi:'',
                cutOffTime:'Cut Off Time',
            },
            Notes:'Notes',
            NotesContent:'What do you not understand can watch the video replay',
            more:'more',
            Copyright:'Copyright of Shenglong Entertainment',
            ThisYear:'ThisYear',
            LastYear:'LastYear',
            enterNum:'Please enter the number',
            LiveStreamingLottery:'Live Streaming Lottery',
            NotesVideo:'Your browser does not support the HTML5 video tag.',
            VideoPric:'First Prize ￥',
            yuan:'',
        }
    }
})