<template>
    <div>
        <header>
            <HomeHeader />
        </header>
            <router-view />
        <footer>
            <HomeFooter />
        </footer>
    </div>
</template>

<script>
import HomeHeader from '../components/common/HomeHeader'
import HomeFooter from '../components/common/HomeFooter'
export default {
    name: 'Home',
    components: {
        HomeHeader, HomeFooter
    }
}
</script>
<style scoped>

</style>