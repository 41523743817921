<template>
    <el-dialog :visible.sync="detailVisible" :before-close="handleClose" width="800px">
        <div class="head">
            <div class="logo"><img src="../../assets/imgss/video_4d.png" alt=""></div>
            <div class="jiang">
                <div class="title">
                    <div>4D</div>
                    <div>期号:{{ issue }}</div>
                </div>
                <div class="title mar_top">
                    <div>首奖：<span class="value">{{ dataList[0] == '' ? '--' : dataList[0] == undefined ? '--' :
                        dataList[0] }}</span></div>
                    <div>二奖：<span class="value">{{ dataList[1] == '' ? '--' : dataList[1] == undefined ? '--' :
                        dataList[1] }}</span></div>
                    <div>三奖：<span class="value">{{ dataList[2] == '' ? '--' : dataList[2] == undefined ? '--' :
                        dataList[2] }}</span></div>
                </div>
            </div>
        </div>
        <div class="content_v">
            <div class="each_e">
                特别奖：
                <span class="value">{{ dataList[3] }}</span>
                <span class="value">{{ dataList[4] }}</span>
                <span class="value">{{ dataList[5] }}</span>
                <span class="value">{{ dataList[6] }}</span>
                <span class="value">{{ dataList[7] }}</span>
                <span class="value">{{ dataList[8] }}</span>
                <span class="value">{{ dataList[9] }}</span>
                <span class="value">{{ dataList[10] }}</span>
                <span class="value">{{ dataList[11] }}</span>
                <span class="value">{{ dataList[12] }}</span>
            </div>
            <div class="each_e">
                安慰奖：
                <span class="value">{{ dataList[13] }}</span>
                <span class="value">{{ dataList[14] }}</span>
                <span class="value">{{ dataList[15] }}</span>
                <span class="value">{{ dataList[16] }}</span>
                <span class="value">{{ dataList[17] }}</span>
                <span class="value">{{ dataList[18] }}</span>
                <span class="value">{{ dataList[19] }}</span>
                <span class="value">{{ dataList[20] }}</span>
                <span class="value">{{ dataList[21] }}</span>
                <span class="value">{{ dataList[22] }}</span>
            </div>
        </div>
        <div>
            <video ref="video1" width="760" :autoplay="true" :controls="true" :loop="false" :muted="false" preload="auto"
                :showPlay="true" :playWidth="96" zoom="cotain">
                <source :src=src type="video/mp4">
                {{ $t('NotesVideo') }}
            </video>
        </div>
    </el-dialog>
</template>
    
<script>
export default {
    data() {
        return {
            detailVisible: false,
            dataList: [],
            src: '',
            issue: ''
        }
    },
    methods: {
        init(data) {
            this.issue = data.issue;
            this.detailVisible = true;
            this.src = data.videourl;
            var data_d = data.lotterynum.split(',');
            for (let index = 0; index < data_d.length; index++) {
                this.dataList.push(data_d[index]);
            }
        },
        handleClose(done) {
            var myVideo = this.$refs.video1
            myVideo.pause();
            done();
        }
    }
}
</script>
<style scoped>
.el-dialog__body {
    padding: 0px;
}

.head {
    display: flex;
    justify-content: space-between;
    padding: 10px 10px 0px 10px;
}

.logo {
    width: 100px;
}

.jiang {
    width: 700px;
}

.mar_top {
    margin-top: 25px;
}

.value {
    font-weight: bold;
    color: #DD967B;
    margin-left: 12px;
}

.title {
    display: flex;
    justify-content: space-between;
}

.content_v {
    background-color: #FFF3E7;
    padding: 20px 30px;
}

.content_v .each_e {
    height: 40px;
    line-height: 40px;
}
</style>
 