<template>
  <div class="foot">
    <div class="div_box foot_flex">
      <div class="f_logo">
        <router-link to="/home">
          <img src="../../assets/imgss/logo_foot.png" @click="GoTop"
        /></router-link>
      </div>
      <div class="copyright">
        <span>{{ $t("Copyright") }}</span
        ><br />
        <span>COPYRIGHT©2015</span>
      </div>
      <div class="contact">
        <div class="toTop">
          <img src="../../assets/imgss/toTop.png" @click="GoTop" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeFooter",
  methods: {
    GoTop() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
  },
};
</script>

<style scoped>
/* foot */
.foot {
  min-width: 1200px;
  margin-top: 15px;
  min-height: 90px;
  background-color: #303030;
  width: 100%;
}

.foot_flex {
  display: flex;
  justify-content: space-between;
  margin: 0px auto;
}

.f_logo {
  width: 250px;
  padding-top: 15px;
}

.copyright {
  color: #636363;
  width: 550px;
  font-weight: bold;
  padding-top: 20px;
  line-height: 30px;
}

.copyright span {
  display: inline-block;
}

.contact {
  width: 400px;
}

.toTop {
  float: right;
}

.toTop:hover {
  cursor: pointer;
}

.contact_p {
  display: flex;
  justify-content: space-between;
  width: 400px;
  margin-top: 50px;
  color: #636363;
}

.contact_p .contact_p_p {
  background: url(../../assets/imgss/phone.png) no-repeat left;
  font-weight: bold;
  width: 160px;
  height: 30px;
  line-height: 30px;
  padding-left: 32px;
}

.contact_p .contact_p_e {
  background: url(../../assets/imgss/mail.png) no-repeat left;
  font-weight: bold;
  height: 30px;
  line-height: 30px;
  display: block;
  padding-left: 32px;
}
</style>