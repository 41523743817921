<template>
  <div class="marginTop">
    <div class="banner">
      <img src="../../assets/imgss/aboutUs_banner.jpg" />
    </div>
    <div class="navigation div_box">
      <a class="link_a">{{ $t("navigation.home") }}</a
      >-
      <span>{{ $t("navigation.aboutUs") }}</span>
    </div>
    <div class="content div_box">
      <div class="Classic_title">
        <div class="Classic_title_c">
          {{ $t("navigation.aboutUs") }}
        </div>
      </div>
      <div class="aboutUs">
        <div class="aboutUs_a">
          <div class="aboutUs_a_t">
            <p class="t_t">天天有奖 时时加码！</p>
            <p class="t_a">
              澳門六合彩是從1至49個號碼中選出六個為中獎號碼的獎券，由「賽馬會獎券奧門分公司」經辦。澳門六合彩每晚攪珠一次，並於網絡現場直播。
            </p>
          </div>
          <div class="aboutUs_a_tt">
            <table>
              <tr>
                <td class="gang">—</td>
                <td class="gang_r">真实</td>
              </tr>
              <tr>
                <td></td>
                <td class="gang_c">
                  每一个环节都有6个摄像头，从每个角度拍摄，杜绝一起作假事件！
                </td>
              </tr>
            </table>
            <table>
              <tr>
                <td class="gang">—</td>
                <td class="gang_r">可靠</td>
              </tr>
              <tr>
                <td></td>
                <td class="gang_c">
                  我公司成立了10年了，有雄厚的资金后盾，完全可以成为你坚强的臂膀！
                </td>
              </tr>
            </table>
            <table>
              <tr>
                <td class="gang">—</td>
                <td class="gang_r">用心</td>
              </tr>
              <tr>
                <td></td>
                <td class="gang_c">坚持一丝不苟的品质，力求每个细节的完美！</td>
              </tr>
            </table>
          </div>
        </div>
        <div class="aboutUs_i">
          <img src="../../assets/imgss/aboutUs_c.jpg" />
        </div>
      </div>
    </div>
    <div class="play">
      <img src="../../assets/imgss/aboutUs_play.jpg" />
    </div>
    <div class="play_content">
      <div class="play_g"></div>
      <div class="play_t">
        腾龙4D游戏规则：你将赢取奖金如你投注的4D号码与开彩号码匹配。
      </div>
      <div class="play_tb">
        <table cellspacing="0">
          <thead>
            <td style="width: 20%; background-color: #f1e2cd; font-size: 20px">
              奖项
            </td>
            <td style="width: 40%; background-color: #f1e2cd; font-size: 20px">
              大
            </td>
            <td style="background-color: #f1e2cd; font-size: 20px">小</td>
          </thead>
          <tr>
            <td>头奖</td>
            <td>RMB 2750</td>
            <td>RMB 3850</td>
          </tr>
          <tr>
            <td>二奖</td>
            <td>RMB 1100</td>
            <td>RMB 2200</td>
          </tr>
          <tr>
            <td>三奖</td>
            <td>RMB 550</td>
            <td>RMB 1100</td>
          </tr>
          <tr>
            <td>特别奖</td>
            <td>RMB 225</td>
            <td>-</td>
          </tr>
          <tr>
            <td>安慰奖</td>
            <td>RMB 70</td>
            <td>-</td>
          </tr>
        </table>
      </div>
      <div class="txt_v">
        <span
          >SA档SA：比如客户在B下注10元，开奖号码一等奖是1389，客户中奖赔率：7350倍。</span
        ><br /><br />
        <span class="txt_v_l"
          >3C档:在3D中下注，投注OXXX,3个号码，比如客户选择0389，一等奖,二等奖，三等奖的后三位有389，客户中奖。</span
        ><br /><br />
        <span class="txt_v_l"
          >3A档:
          在3D中下注，投注OXXX,3个号码，比如客户选择0389，一等奖的后三位有389，客户中奖。</span
        ><br />
        <div style="margin-top: 50px; ">
          1.关于BOX（打字）,I-BOX（全包字）的玩法：<br /><br />
          <div style="padding-left:20px;">BOX（打字）：</div>
          <div style="padding:20px;">
            第一种 ，三个号码一样的例如8889-3338-7775这些号码都属于4打字<br /><br />

            第二种，两个两个号码一样的例如8899-5566-3344这些号码都属于6打字<br /><br />

            第三种，四个号码里面有两个一样的例如8892-5563-7749这些号码都属于12打字<br /><br />

            第四种，四个号码都不一样的例如1234-5678-9231这些号码都属于24打字<br />
          </div>
        </div>
        <div>
            比喻 = 如果顾客选择打字的话8989-10-10，这个号码属于6打字，收费是120马币。投注多少钱就✖多少打字的来收费！
        </div>
        <div style="margin-top:10px;">
            比喻 = 如果顾客选择全包字的话8989-10-10，这个号码属于6打字，收费是20马币而已。但是中奖时中奖金额就要➗6打字得到奖金！
        </div>
        <div style="margin-top: 50px; ">
          <div style="padding-left:20px;">I-BOX（全包字）：</div>
          <div style="padding:20px; line-height: 30px;">
            全包字的玩法：假如客户以I-BOX（全包字）的方式下注买了一组号码7235，如果这组数字开出一等奖，不管这四个数字的排序如何，比如7325.5327.2735.3725.5237.2375.3275.3257，只要是这四个数字的个组合开出，即中奖。
          </div>
          <div style="padding:20px; line-height: 30px;">
            赔率：I-BOX（全包字）他相当于24个排序组合的数字，如客户的投注是1元，开出的一等奖是3257的号码，一等奖的奖金是2750需除以24，等于114.583元
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "AboutUs",
};
</script>
<style scoped>
.txt_v {
  padding: 20px 60px;
}
.txt_v_l {
  padding-top: 20px;
}
.play_tb {
  width: 1112px;
  margin: 0 auto;
}

.play_tb table {
  width: 100%;
  border-collapse: collapse;
}

.play_tb thead {
  background-color: #f1e2cd;
  color: #b88342;
  text-align: center;
  font-size: 20px;
  font-weight: bolder;
}

.play_tb td {
  border: 1px solid #f0ddc0;
  height: 50px;
  padding-left: 12px;
}

.play_tb td:first-child {
  text-align: center;
  background-color: #fffbf5;
}

.play_tb td:second-child {
  text-align: center;
  background-color: #fffbf5;
}

.play_tb td:last-child {
  background-color: #fffbf5;
}

.play {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.play img {
  max-width: 100%;
  height: auto;
}
.play_content {
  background-color: white;
  width: 1200px;
  margin: 0 auto;
  position: relative;
  margin-top: -230px;
  padding-top: 20px;
  padding-bottom: 30px;
  margin-bottom: 30px;
}

.play_g {
  width: 87px;
  height: 10px;
  background-color: #efdbc0;
  border-radius: 0px 5px 5px 0px;
}

.play_t {
  margin-top: 10px;
  padding: 20px 60px;
  line-height: 25px;
  color: #767676;
}

.gang {
  font-size: 25px;
  font-weight: 800;
  color: rgb(18, 18, 173);
  width: 35px;
}

.gang_r {
  font-size: 25px;
  font-weight: 800;
}

.gang_c {
  color: #767676;
}

.aboutUs_a_tt {
  margin-top: 60px;
}

.aboutUs_a_tt table {
  margin-top: 20px;
}

.aboutUs_a_tt table td {
  height: 30px;
  line-height: 30px;
}

.t_t {
  font-size: 32px;
  font-weight: bolder;
  line-height: 30px;
}

.t_a {
  margin-top: 10px;
  line-height: 25px;
  color: #767676;
}

.content {
  background-color: white;
}

.aboutUs {
  display: flex;
  flex-direction: row;
}

.aboutUs_a {
  width: 680px;
  background: url(../../assets/imgss/aboutUs_b.jpg) no-repeat bottom left;
  padding: 20px 50px;
}

.aboutUs_i {
  margin-top: 30px;
  height: 500px;
}

.navigation {
  margin-top: 20px;
}

.navigation span {
  font-weight: bold;
  color: #3f3f3f;
}

/*banner*/
.banner {
  display: flex;
  justify-content: center;
  min-width: 920px;
}

.banner img {
  min-width: 1480px;
  width: 100%;
  height: auto;
}

.Classic_title {
  width: 1200px;
  background-color: #f8eee2;
  /* background-image: linear-gradient(to right, #F8EEE2, #F8EEE2, white); */
  margin-top: 10px;
}

.Classic_title_c {
  background: url(../../assets/imgss/title.png) no-repeat;
  height: 50px;
  width: 209px;
  text-align: center;
  color: #806142;
  font-size: 20px;
  line-height: 50px;
  font-weight: bolder;
}
</style>