<template>
    <div class="Classic_content">
        <div class="Classic_content_title">
            <div class="title">
                <span class="span">4D CLASSIC</span>{{ $t('Awards.NO') }}<label>{{ dataList.issue }}</label>{{
                    $t('Awards.qi') }}
            </div>
            <div class="box_show">
                <span ref="show" v-bind:class="show" @click="showBox"></span>
            </div>
        </div>
        <div ref="content" v-bind:class="box" v-show="ifShow">
            <table class="topThree" cellspacing="0">
                <thead>
                    <td>{{ $t('Awards.firstPrize') }}
                    </td>
                    <td>{{ $t('Awards.SecondPlace') }}
                    </td>
                    <td>{{ $t('Awards.ThirdPrize') }}
                    </td>
                </thead>
                <tr>
                    <td>{{ dataListReslut[0] == '' ? '--' : dataListReslut[0] == undefined ? '--' : dataListReslut[0] }}
                    </td>
                    <td>{{ dataListReslut[1] == '' ? '--' : dataListReslut[1] == undefined ? '--' : dataListReslut[1] }}
                    </td>
                    <td>{{ dataListReslut[2] == '' ? '--' : dataListReslut[2] == undefined ? '--' : dataListReslut[2] }}
                    </td>
                </tr>
            </table>
            <table class="table_other" cellspacing="0">
                <thead>
                    <td colspan="4" style="border-top: none;">{{ $t('Awards.SpecialPrize') }}
                    </td>
                </thead>
                <tr>
                    <td>{{ dataListReslut[3] == '' ? '--' : dataListReslut[3] == undefined ? '--' : dataListReslut[3] }}
                    </td>
                    <td>{{ dataListReslut[4] == '' ? '--' : dataListReslut[4] == undefined ? '--' : dataListReslut[4] }}
                    </td>
                    <td>{{ dataListReslut[5] == '' ? '--' : dataListReslut[5] == undefined ? '--' : dataListReslut[5] }}
                    </td>
                    <td>{{ dataListReslut[6] == '' ? '--' : dataListReslut[6] == undefined ? '--' : dataListReslut[6] }}
                    </td>
                </tr>
                <tr>
                    <td>{{ dataListReslut[7] == '' ? '--' : dataListReslut[7] == undefined ? '--' : dataListReslut[7] }}
                    </td>
                    <td>{{ dataListReslut[8] == '' ? '--' : dataListReslut[8] == undefined ? '--' : dataListReslut[8] }}
                    </td>
                    <td>{{ dataListReslut[9] == '' ? '--' : dataListReslut[9] == undefined ? '--' : dataListReslut[9] }}
                    </td>
                    <td>{{ dataListReslut[10] == '' ? '--' : dataListReslut[10] == undefined ? '--' : dataListReslut[10] }}
                    </td>
                </tr>
                <tr>
                    <td class="kong"></td>
                    <td>{{ dataListReslut[11] == '' ? '--' : dataListReslut[11] == undefined ? '--' : dataListReslut[11] }}
                    </td>
                    <td>{{ dataListReslut[12] == '' ? '--' : dataListReslut[12] == undefined ? '--' : dataListReslut[12] }}
                    </td>
                    <td class="kong"></td>
                </tr>
            </table>
            <table class="table_other" cellspacing="0">
                <thead>
                    <td colspan="4" style="border-top: none;">{{ $t('Awards.ConsolationPrize') }}
                    </td>
                </thead>
                <tr>
                    <td>{{ dataListReslut[13] == '' ? '--' : dataListReslut[13] == undefined ? '--' : dataListReslut[13] }}
                    </td>
                    <td>{{ dataListReslut[14] == '' ? '--' : dataListReslut[14] == undefined ? '--' : dataListReslut[14] }}
                    </td>
                    <td>{{ dataListReslut[15] == '' ? '--' : dataListReslut[15] == undefined ? '--' : dataListReslut[15] }}
                    </td>
                    <td>{{ dataListReslut[16] == '' ? '--' : dataListReslut[16] == undefined ? '--' : dataListReslut[16] }}
                    </td>
                </tr>
                <tr>
                    <td>{{ dataListReslut[17] == '' ? '--' : dataListReslut[17] == undefined ? '--' : dataListReslut[17] }}
                    </td>
                    <td>{{ dataListReslut[18] == '' ? '--' : dataListReslut[18] == undefined ? '--' : dataListReslut[18] }}
                    </td>
                    <td>{{ dataListReslut[19] == '' ? '--' : dataListReslut[19] == undefined ? '--' : dataListReslut[19] }}
                    </td>
                    <td>{{ dataListReslut[20] == '' ? '--' : dataListReslut[20] == undefined ? '--' : dataListReslut[20] }}
                    </td>
                </tr>
                <tr>
                    <td class="kong"></td>
                    <td>{{ dataListReslut[21] == '' ? '--' : dataListReslut[21] == undefined ? '--' : dataListReslut[21] }}
                    </td>
                    <td>{{ dataListReslut[22] == '' ? '--' : dataListReslut[22] == undefined ? '--' : dataListReslut[22] }}
                    </td>
                    <td class="kong"></td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LotteryHistory',
    props: ['list'],
    data() {
        return {
            ifShow: true,
            show: 'open',
            box: 'record_content_open',
            dataList: [],
            dataListReslut: []
        }
    },
    mounted() {
        // console.log(this.list)
        if (this.list.ifShow == true) {
            this.ifShow = true
        }
        else {
            this.ifShow = false
        }
        this.dataList = this.list
        var data_d = this.dataList.lotterynum.split(",");
        for (let index = 0; index < data_d.length; index++) {
          this.dataListReslut.push(data_d[index]);
        }
    },
    methods: {
        showBox() {
            // this.ifShow = !this.ifShow
            if (this.ifShow) {
                this.show = 'close'
                // this.box = 'record_content_close'
                this.ifShow = false
            }
            else {
                this.show = 'open'
                // this.box = 'record_content_open'
                this.ifShow = true
            }
        }
    }
}
</script>

<style scoped>
.kong {
    background-color: #e9e9e9;
}

.record_content_open {
    background-color: white;
    width: 1095px;
    margin: auto;
    padding-top: 15px;
    height: 300px;
    transition: width 1s;
    -webkit-transition: width 1s;
    /* Safari */
}

.record_content_close {
    background-color: white;
    width: 1095px;
    margin: auto;
    padding-top: 15px;
    /* transition-property: display;
    transition-duration: 5s; */
    /* animation: animate 2s ease infinite; */
    height: 0px;
}

/* @keyframes animate {
    from {
           height: 300px;
        top : 0px;
    }
    to {
        height: 0px;
        top : 300px;
    }
} */

.box_show {
    height: 40px;

}

.box_show .open {
    display: block;
    width: 0px;
    height: 0px;
    border-width: 13px;
    border-style: solid;
    border-color: #EDD1A8 transparent transparent transparent;
    overflow: hidden;
    margin: 15px 20px 0px 0px;
    /* transition:border-color 2s;
	-webkit-transition:border-color 2s;  */
}

.box_show .close {
    display: block;
    width: 0px;
    height: 0px;
    border-width: 13px;
    border-style: solid;
    border-color: transparent transparent #EDD1A8 transparent;
    overflow: hidden;
    margin: 0px 20px 15px 0px;
    /*  transition:border-color 2s;
	-webkit-transition:border-color 2s; */
}

/* .open {
    display: block;
    background: url(../../assets/imgss/open.png) no-repeat;
    width: 20px;
    height: 13px;
}

.close {
    display: block;
    background: url(../../assets/imgss/close.png) no-repeat;
    width: 20px;
    height: 13px;
} */


.Classic_content_title {
    background-color: #F0F0F0;
    width: 1140px;
    font-size: 25px;
    height: 40px;
    line-height: 40px;
    margin-top: 10px;
    margin-left: 30px;
    display: flex;
    justify-content: space-between;
}

.Classic_content_title .title {
    font-size: 25px;
    height: 40px;
    line-height: 40px;
}



.Classic_content_title .box_show span:hover {
    cursor: pointer;
}

.Classic_content_title .title .span {
    font-size: 30px;
    margin-top: 10px;
    font-weight: bolder;
    padding-right: 15px;
    padding-left: 10px;
}

.Classic_content_title label {
    font-size: 25px;
    color: red;
    margin-top: 10px;
    font-weight: 400;
    padding: 0px 5px;
}



.topThree {
    width: 100%;
    border-collapse: collapse;
}

.topThree thead {
    background-color: #EAC894;
    color: #5B3411;

}

.topThree td {
    border: 1px solid #EAC894;
    text-align: center;
    font-size: 20px;
    font-weight: bolder;
}

.table_other {
    width: 100%;
    border-collapse: collapse;
}

.table_other thead {
    background-color: #F6EDDC;
    color: #5B3411;

}

.table_other td {
    border: 1px solid #EAC894;
    text-align: center;
    font-size: 20px;
    font-weight: bolder;
}
</style>