<template>
    <div class="marginTop">
        <div class="banner">
            <img src="../../assets/imgss/faq.jpg" />
        </div>
        <div class="navigation div_box">
            <a class="link_a">{{ $t('navigation.home') }}</a>-
            <span>FAQ</span>
        </div>
        <div class="Classic_title div_box">
            <div class="Classic_title_c">
                4D CLASSIC FAQ
            </div>
            <div class="content">
                <div class="FAQ">
                    <div class="each_FAQ">
                        <div class="problem">
                            <span>1</span>、<span>澳門六合彩在什麼時間段可以購買？</span>
                        </div>
                        <div class="answer">
                            答：澳門六合彩票為區域性彩種，請根據站點的具體營運時間購買。
                        </div>
                    </div>
                    <div class="each_FAQ">
                        <div class="problem">
                            <span>1</span>、<span>澳門六合彩在什麼時間段可以購買？</span>
                        </div>
                        <div class="answer">
                            答：澳門六合彩票為區域性彩種，請根據站點的具體營運時間購買。
                        </div>
                    </div>
                    <div class="each_FAQ">
                        <div class="problem">
                            <span>1</span>、<span>澳門六合彩在什麼時間段可以購買？</span>
                        </div>
                        <div class="answer">
                            答：澳門六合彩票為區域性彩種，請根據站點的具體營運時間購買。
                        </div>
                    </div>
                    <div class="each_FAQ">
                        <div class="problem">
                            <span>1</span>、<span>澳門六合彩在什麼時間段可以購買？</span>
                        </div>
                        <div class="answer">
                            答：澳門六合彩票為區域性彩種，請根據站點的具體營運時間購買，請根據站點的具體營運時間購買，請根據站點的具體營運時間購買，請根據站點的具體營運時間購買，請根據站點的具體營運時間購買。
                        </div>
                    </div>
                </div>
                <div class="config">
                    <div class="config_title">技術支持</div>
                    <div class="config_title_t">最低硬件系統要求：</div>
                    <div class="config_config_c">1、可以接上互聯網的計算機；<br>
                        2、區域寬帶；<br>
                        3、Windows , Mac OS X , Linux , Android操作系統其中之一；<br>
                        4、Internet Explorer瀏覽器v10.0 或以上，Mozilla Firefox (瀏覽器v3.0 或以上)，Opera (瀏覽器v8.0
                        或以上)，Chrome(瀏覽器v6.0或以上)，Safari(瀏覽器v4.0 或以上)。</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Faq'
}
</script>
<style scoped>
.config {
    margin: 20px 30PX;
    background-color: #F0F0F0;
    padding: 20PX 30px;
    border-radius: 10px;
}

.config_title {
    text-align: center;
    color: #B78445;
    font-size: 20px;
    font-weight: 800;
}

.config_title_t {
    font-weight: 800;
}

.config_config_c {
    color: #b0b0b0;
    padding: 5px 10px;
    line-height: 25px;
}

.content_b {
    background-color: white;
}

/*banner*/
.banner {
    display: flex;
    justify-content: center;
    min-width: 920px;
}

.banner img {
    min-width: 1480px;
    width: 100%;
    height: auto;

}
.navigation {
    margin-top: 20px;
}

.navigation span {
    font-weight: bold;
    color: #3f3f3f;
}
.Classic_title {

    width: 1200px;
    background-color: #F8EEE2;
    /* background-image: linear-gradient(to right, #F8EEE2, #F8EEE2, white); */
    margin-top: 10px;
    position: relative;
}

.Classic_title_c {
    background: url(../../assets/imgss/title.png) no-repeat;
    width: 209px;
    text-align: center;
    color: #806142;
    font-size: 20px;
    line-height: 50px;
    font-weight: bolder;
}

.content {

    background-color: white;
    padding-bottom: 10PX;
}

.FAQ {
    padding: 15px 45px;
}

.each_FAQ {
    padding: 15px 0px;
}

.problem {
    font-weight: bolder;
}

.answer {
    margin-top: 5px;
    color: #DAB479;
    line-height: 25px;
}</style>