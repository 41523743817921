<template>
    <div class="head">
        <div class="banner">
            <div class="logo">
                <router-link to="/home" @click.native="GoTop">
                </router-link>
            </div>
            <div class="menu">
                <div class="menu_i">
                    <router-link to="/home" active-class="selected" @click.native="GoTop">
                        {{ $t('navigation.home') }}</router-link>
                </div>|
                <div class="menu_i">
                    <router-link to="/lotteryAnnouncement" active-class="selected" @click.native="GoTop">
                        {{ $t('navigation.notice') }}</router-link>
                </div>|
                <div class="menu_i">
                    <router-link to="/lotteryVideo" active-class="selected" @click.native="GoTop">
                        {{ $t('navigation.video') }}</router-link>
                </div>|
                <div class="menu_i">
                    <router-link to="/faq" active-class="selected" @click.native="GoTop">
                        FAQ</router-link>
                </div>|
                <div class="menu_i">
                    <router-link to="/aboutUs" active-class="selected" @click.native="GoTop">
                        {{ $t('navigation.aboutUs') }}</router-link>
                </div>|
                <div class="menu_l">
                    <div v-bind:class="language" class="img" ref="language" @click="showBox"></div>
                    <div class="languageBox" v-show="languageBox">
                        <ul>
                            <li @click="changeLanguage('zh')">简体中文</li>
                            <li @click="changeLanguage('en')">English</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="head_f" @click="showBox"></div>
        <!-- <div class="languageBox" v-show="languageBox">
            <ul>
                <li @click="changeLanguage('zh')">简体中文</li>
                <li @click="changeLanguage('en')">English</li>
            </ul>
        </div> -->
    </div>
</template>

<script>
import i18n from '@/i18n';
export default {
    name: 'HomeHeader',
    data() {
        return {
            language: 'chinese',
            languageBox: false
        }
    },
    methods: {
        GoTop() {
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        },
        showBox() {
            // this.language = 'english'
            // this.$i18n.locale = 'en'
            this.languageBox = !this.languageBox
        },
        changeLanguage(lang) {
            this.$i18n.locale = lang
            this.languageBox = false
            if (lang == 'zh') {
                this.language = 'chinese'
            }
            else if (lang == 'en') {
                this.language = 'english'
            }
        }
    },

}
</script>

<style scoped>
.languageBox {
    position: fixed;
    background-color: white;
    border: 1px solid rgb(238, 238, 238);
    width: 100px;
    color: black;
    font-size: 18px;
}

.languageBox li {
    height: 40px;
    line-height: 40px;
}

.languageBox li:hover {
    cursor: pointer;
}

/*head*/
.head {
    height: 89px;
    display: flex;
    justify-content: space-between;
    position: fixed;
    background-color: #FAF7F6;
    width: 100%;
    z-index: 50;
    top: 0px;
    border-bottom: 1px solid #eeeeed;
}

.banner {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.logo {
    width: 253px;
    height: 89px;
    padding-left: 100px;
}

.logo a {
    background: url(../../assets/imgss/logo.png) no-repeat;
    width: 253px;
    height: 89px;
    display: block;
}

.menu {
    color: #B8B8B8;
    display: flex;
    flex-direction: row;
    text-align: center;
    height: 89px;
    line-height: 89px;
    float: right;
    widows: 680px;
}

.menu .menu_i {
    width: 110px;
    height: 89px;
    line-height: 89px;
    color: #e1af5b;
}

.menu .menu_l {
    padding: 36px 30px 0px 30px;
}

.menu .menu_l .chinese {
    background: url(../../assets/imgss/chinese.png) no-repeat center;
    width: 25px;
    height: 17px;

}

.menu .menu_l .english {
    background: url(../../assets/imgss/english.png) no-repeat center;
    width: 25px;
    height: 17px;

}

.menu .menu_l .img:hover {
    cursor: pointer;
}

.menu a {
    padding-right: 6px;
    color: #e1af5b;
    font-weight: bold;
    text-align: center;
    display: block;
}

.menu .selected {
    color: white;
    background: url(../../assets/imgss/menu_back1.png) no-repeat center;
}

.active {
    color: white;
    /* background: url(../../assets/imgss/menu_back.png) no-repeat center; */
}

.head_f {
    background: url(../../assets/imgss/head_f.png) no-repeat;
    height: 89px;
    width: 86px;
}

.head_f:hover {
    cursor: pointer;
}
</style>