<template>
  <div id="app">
    <Home/>
    <!-- <router-view /> -->
  </div>
</template>

<script>
import Home from './views/Home.vue'
export default {
  name: 'App',
  components: {
    Home
  }
}
</script>

<style>
/*公共样式*/
* {
  font-family: 微软雅黑;
  letter-spacing: 1px;
  font-size: 16px;
}

body {

  margin: 0px;
  padding: 0px;
  background-color: #FAF7F6;
}

img {
  border: none;
}

a {
  color: Black;
  text-decoration: none;
  font-family: 微软雅黑;
  background-color: transparent;
}

ul {
  padding: 0px;
  margin: 0px;
}
.marginTop{
    margin-top:90px;
}
ol,
ul {
  list-style: none;
}

.div_box {
  width: 1200px;
  margin: 0 auto;
}
.link_a{
  color: #AAA8A9;
}
input::-webkit-input-placeholder {
/* WebKit browsers */
color: #d1d1d1;
}
input:-moz-placeholder {
/* Mozilla Firefox 4 to 18 */
color: #d1d1d1;
}
input::-moz-placeholder {
/* Mozilla Firefox 19+ */
color: #d1d1d1;
}
input::-ms-input-placeholder {
/* Internet Explorer 10+ */
color: #d1d1d1;
}
</style>
