<template>
  <div class="marginTop">
    <div class="banner">
      <img src="../../assets/imgss/lotteryHistory.jpg" />
      <div class="show">
        <a class="show_img" href="javaScript:void(0);"></a>
      </div>
    </div>
    <div class="navigation div_box">
      <router-link to="/home"> {{ $t("navigation.home") }}</router-link
      >-
      <span>{{ $t("navigation.notice") }}</span>
    </div>
    <div class="div_box content">
      <div class="Classic_now">
        <div class="Classic_title">
          <div class="Classic_title_c">4D CLASSIC</div>
        </div>
        <LotteryNow />
      </div>
      <div class="Classic_record">
        <div class="Classic_title">
          <div class="Classic_title_c">
            {{ $t("Awards.DrawingHistory") }}
          </div>
          <div class="Classic_title_s">
            <div class="history">
              <div v-bind:class="thisYear" @click="check(0)">
                {{ $t("ThisYear") }}
              </div>
              <div v-bind:class="lastYear" @click="check(1)">
                {{ $t("LastYear") }}
              </div>
            </div>
            <div class="search" @click="onblur">
              <input
                type="text"
                ref="search"
                v-model="searchValue"
                @keydown.enter="search"
                placeholder="请输入期号"
                class="search_i"
              />
            </div>
          </div>
        </div>
        <div v-for="(list, index) in dataList" :key="index">
          <LotteryHistoryModel :list="list" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LotteryHistoryModel from "../../components/common/LotteryHistoryModel";
import LotteryNow from "../../components/common/LotteryNow";
export default {
  name: "LotteryAnnouncement",
  components: {
    LotteryHistoryModel,
    LotteryNow,
  },
  data() {
    return {
      thisYear: "thisYear",
      lastYear: "lastYear",
      dataList: [],
      searchValue: "",
    };
  },
  mounted() {
    const axios = require("axios");
    var _this = this;
    axios
      .get(
        "https://www.kkonline.app/game/TL4DHomeWeb/GetOpenVideo?pageindex=1&pagesize=10&type=1"
      )
      .then(function (res) {
        for (var i = 1; i < res.data.data.length; i++) {
          if (i < 3) {
            res.data.data[i]["ifShow"] = true;
            _this.dataList.push(res.data.data[i]);
          } else {
            res.data.data[i]["ifShow"] = false;
            _this.dataList.push(res.data.data[i]);
          }
        }
      });
  },
  methods: {
    check(val) {
      if (val == 0) {
        this.thisYear = "thisYear";
        this.lastYear = "lastYear";
        this.dataList = [];
        const axios = require("axios");
        var _this = this;
        axios
          .get(
            "https://www.kkonline.app/game/TL4DHomeWeb/GetOpenVideo?pageindex=1&pagesize=10&type=1"
          )
          .then(function (res) {
            for (var i = 1; i < res.data.data.length; i++) {
              if (i < 3) {
                res.data.data[i]["ifShow"] = true;
                _this.dataList.push(res.data.data[i]);
              } else {
                res.data.data[i]["ifShow"] = false;
                _this.dataList.push(res.data.data[i]);
              }
            }
          });
      } else {
        this.thisYear = "lastYear";
        this.lastYear = "thisYear";
        this.dataList = [];
        const axios = require("axios");
        var _this = this;
        axios
          .get(
            "https://www.kkonline.app/game/TL4DHomeWeb/GetOpenVideo?pageindex=1&pagesize=10&type=0"
          )
          .then(function (res) {
            for (var i = 1; i < res.data.data.length; i++) {
              if (i < 3) {
                res.data.data[i]["ifShow"] = true;
                _this.dataList.push(res.data.data[i]);
              } else {
                res.data.data[i]["ifShow"] = false;
                _this.dataList.push(res.data.data[i]);
              }
            }
          });
      }
    },
    onblur() {
      this.$refs.search.focus();
    },
    search() {
      this.dataList = [];
      const axios = require("axios");
      var _this = this;
      if (this.searchValue.trim() != "") {
        axios
          .get(
            "https://www.kkonline.app/game/TL4DHomeWeb/GetOpenVideo?pageindex=1&pagesize=10&type=1&issue=" +
              _this.searchValue
          )
          .then(function (res) {
            if (res.data.data.length > 0) {
              res.data.data[0]["ifShow"] = true;
              _this.dataList.push(res.data.data[0]);
            }
          });
      } else {
        axios
          .get(
            "https://www.kkonline.app/game/TL4DHomeWeb/GetOpenVideo?pageindex=1&pagesize=10&type=1"
          )
          .then(function (res) {
            for (var i = 1; i < res.data.data.length; i++) {
              if (i < 3) {
                res.data.data[i]["ifShow"] = true;
                _this.dataList.push(res.data.data[i]);
              } else {
                res.data.data[i]["ifShow"] = false;
                _this.dataList.push(res.data.data[i]);
              }
            }
          });
      }
    },
  },
};
</script>
<style scoped>
.history {
  width: 220px;
  display: flex;
  justify-content: space-between;
}

.thisYear {
  width: 95px;
  height: 40px;
  background-color: #ebc996;
  color: #5b3411;
  font-weight: bolder;
  font-size: 20px;
  margin-top: 5px;
  text-align: center;
  line-height: 40px;
  border-radius: 50px;
}

.lastYear {
  width: 95px;
  height: 40px;
  color: #808080;
  font-weight: bolder;
  font-size: 20px;
  margin-top: 5px;
  text-align: center;
  line-height: 40px;
}

.lastYear:hover {
  cursor: pointer;
}

.thisYear:hover {
  cursor: pointer;
}

.search {
  background: url(../../assets/imgss/search.png) no-repeat;
  height: 40px;
  width: 175px;
  margin-top: 5px;
  margin: 5px 50px 0px 0px;
  line-height: 40px;
}

.search input {
  /* font-weight: bolder; */
  border: 0;
  margin-left: 50px;
  outline: none;
  width: 100px;
  background-color: transparent;
  color: #929292;
  font-size: 18px;
  height: 40px;
}

.Classic_title_s {
  width: 500px;
  display: flex;
  justify-content: space-between;
}

/*banner*/
.banner {
  display: flex;
  justify-content: center;
  min-width: 920px;
}

.banner img {
  min-width: 1480px;
  width: 100%;
  height: auto;
}

.show {
  position: absolute;
  background: url(../../assets/imgss/show_img.png) no-repeat;
  height: 48px;
  width: 138px;
  left: 400px;
  top: 510px;
}

.show a {
  display: block;
  height: 48px;
  width: 138px;
}

.navigation {
  margin-top: 15px;
}

.navigation span {
  font-weight: bold;
  color: #3f3f3f;
}

/*content*/
.content {
  margin-top: 10px;
  position: relative;
}

.Classic_now {
  background-color: white;
  padding-bottom: 20px;
}

.Classic_title {
  background: url(../../assets/imgss/title.png) no-repeat;
  height: 50px;
  line-height: 50px;
  width: 1200px;
  background-color: #f8eee2;
  display: flex;
  justify-content: space-between;
}

.Classic_title_c {
  width: 205px;
  text-align: center;
  color: #806142;
  font-size: 20px;
  line-height: 50px;
  font-weight: bolder;
  background-color: transparent;
}

.Classic_record {
  margin-top: 12px;
  background-color: white;
  padding-bottom: 40px;
}

.Classic_record .Classic_title {
  height: 50px;
  width: 1200px;
  background-color: #f8eee2;
  background-image: linear-gradient(to right, #f8eee2, #f8eee2, white);
  display: flex;
  justify-content: space-between;
}

.Classic_record .Classic_title_c {
  background: url(../../assets/imgss/title.png) no-repeat;
  width: 209px;
  text-align: center;
  color: #806142;
  font-size: 20px;
  line-height: 50px;
  font-weight: bolder;
  background-color: transparent;
}
</style>